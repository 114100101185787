// import paymentService from "../../services/paymentService";

export default {
  name: "pay-with-bitrah",
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    payFunc() {
      /*paymentService.submitTestPayment( "150000" , "BTC" , localStorage.getItem( "bitrahAccessToken" ) , "666" , "http://192.168.20.116:9000/order/callback" ).then( response => {*/
      window.open(
        "/" +
          `${this.$i18n.locale}` +
          "/" +
          `guest` +
          "?token=9a2b78e5d33e458da3a2e76bdc4a579a" +
          "&mode=" +
          "on" +
          "&coin=" +
          "BTC" +
          "&amount=" +
          "22000000"
      );
    }
  }
};
